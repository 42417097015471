//todo: refactoring needed for post-mvp
//used in post-mvp
import ReCaptcha from '@/components/atoms/ReCaptcha';
import React, { useEffect, useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { useRouter } from 'next/router';
import { useUser } from '@/context/UserContext';
import { GetServerSideProps } from 'next';
import useLayoutData from '@/hooks/LayoutData';
import useFetchContent from '@/hooks/Content';
import logger from '@/lib/logger';
import postApiCalls from '@/lib/api';
import { getUserCredentials } from '@/lib/Cookielib';

interface LoginProps {
  referer: string;
}
export default function Account(props: LoginProps) {
  const { referer } = props;
  useLayoutData();
  useFetchContent();
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const { addAuthResponse, loggedIn, user } = useUser();

  const [password, setPassword] = useState('');
  const router = useRouter();
  const [error, setError] = useState({
    errorPresent: false,
    errorMessage: '',
  });
  const [authEndpoint] = useState('');

  useEffect(() => {
    if (loggedIn && user?.userEmail) {
      router.push(referer || '/');
    }
  });

  async function handleLogIn(e: React.SyntheticEvent) {
    e.preventDefault();
    setError({
      errorPresent: false,
      errorMessage: '',
    });

    if (email && password) {
      try {
        const res = await postApiCalls({
          url: `${authEndpoint || '/api/auth/login'}`,
          body: {
            email: email.trim(),
            password: password.trim(),
            returnUrl: window.location.href,
          },
        });
        addAuthResponse(res.data);
        router.push(referer);
      } catch (error) {
        logger.error(error);
        setError({
          errorPresent: true,
          errorMessage: 'Something went wrong',
        });
      }
    } else {
      setError({
        errorPresent: true,
        errorMessage: 'Invalid email and password',
      });
    }
  }

  return (
    <section className={'w-full flex justify-center my-8'}>
      <form
        className={
          'border border-gray-200 w-full xl:w-[500px] rounded-xl px-6 py-12'
        }
        onSubmit={handleLogIn}
      >
        <h3 className={'text-xl xl:text-2xl font-medium text-center mb-4'}>
          Login to Your Account
        </h3>
        <div className={'mb-4'}>
          <input
            type="email"
            placeholder={'Email *'}
            data-tracking-id="login-email"
            className={'border-2 border-gray-200 rounded-sm w-full h-16 pl-4'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={'mb-4 flex flex-row'}>
          <input
            type={`${viewPassword ? 'type' : 'password'}`}
            placeholder={'Password *'}
            data-tracking-id="login-password"
            className={
              'border-y-2 border-l-2 border-y-gray-200 border-l-gray-200 rounded-l-sm w-[480px] h-16 pl-4'
            }
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            onClick={() => setViewPassword(!viewPassword)}
            data-tracking-id="login-view-password"
            type="button"
            className={
              'border-y-2 border-r-2 border-y-gray-200 border-r-gray-200 rounded-r-sm w-[40px] h-16 flex justify-center items-center'
            }
          >
            <AiFillEye size={32} color={'#e0e0e0'} />
          </button>
        </div>
        <p className={'text-primary font-medium text-sm text-start my-4'}>
          Forgot your password?
        </p>
        {error.errorPresent && (
          <p className={'font-medium text-sm text-start my-4 text-red-600'}>
            {error.errorMessage}
          </p>
        )}
        <ReCaptcha />
        <button
          onClick={handleLogIn}
          type="submit"
          data-tracking-id="login-button"
          className={
            'w-full text-white bg-primary hover:bg-hoverPrimary focus:ring-4 focus:outline-none focus:ring-white rounded-lg px-5 py-2.5 xl:text-lg text-center xl:uppercase'
          }
        >
          Log In
        </button>
      </form>
    </section>
  );
}
export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const userCredentials = getUserCredentials(req, res);

  try {
    return {
      props: {
        userCredentials: userCredentials ?? null,
      },
    };
  } catch (error: any) {
    logger.error(error);
    return {
      props: {
        useCredentials: null,
        referer: req.headers.referer,
      },
    };
  }
};
